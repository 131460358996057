var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BContainer',{staticClass:"h-100 justify-content-center",class:_vm.width >= 510 ? 'd-flex' : '',style:(_vm.width >= 1000 ? 'width: calc(100% - 56px); margin-right:56px;' : ''),attrs:{"fluid":""}},[_c('BRow',[_c('BCol',{staticClass:"text-left mx-auto",class:_vm.width > 510 ? 'col500' : '',attrs:{"cols":_vm.width <= 510 ? '12' : ''}},[_c('BRow',{staticClass:"text-center py-3 mt-4"},[_c('BCol',{staticClass:"pt-2 text-center",staticStyle:{"z-index":"10"},attrs:{"cols":"1"}},[_c('a',{staticClass:"pl-3",attrs:{"href":`/${_vm.app}-settings/tab/user-management`}},[_c('BIcon',{attrs:{"icon":"chevron-left","scale":"1.2"}})],1)]),_c('BCol',[_c('h3',{staticClass:"text-center"},[_vm._v("Add User")])]),_c('BCol',{attrs:{"cols":"1"}})],1),_c('BForm',{class:_vm.width > 510 ? 'p-3' : 'py-3',on:{"submit":_vm.onSubmit}},[_c('p',{staticClass:"inputBlockLabel pb-0"},[_vm._v("User details")]),_c('FormTextInput',{attrs:{"inputAttrs":{
            id: 'name',
            label: 'Name *',
            labelClass: 'text-primary',
            type: 'text',
            variant: _vm.bootstrapColour
          },"required":"","id":"name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('FormTextInput',{attrs:{"inputAttrs":{
            id: 'email',
            label: 'Email *',
            labelClass: 'text-primary',
            type: 'email',
            variant: _vm.bootstrapColour
          },"id":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('PhoneNumberInput',{attrs:{"inputAttrs":{
            phone: _vm.form.phone
          }},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('p',{staticClass:"inputBlockLabel pb-0"},[_vm._v("Roles and permissions")]),_c('FormDropdownInput',{attrs:{"inputAttrs":{
            id: 'role',
            label: 'Role *',
            options: ['Admin', 'Read Only'],
            variant: _vm.bootstrapColour,
            value: _vm.form.role
          },"id":"role"},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}}),_c('div',{staticClass:"justify-content-center d-flex"},[_c('BButton',{staticClass:"text-white mt-3 px-5",attrs:{"type":"submit","variant":_vm.bootstrapColour}},[_vm._v("Submit")])],1),_c('p',{staticClass:"text-white text-center pt-3"},[_c('a',{class:`text-${_vm.bootstrapColour}`,attrs:{"href":`/${_vm.app}-settings/tab/user-management`}},[_vm._v("Back to settings")])]),(_vm.error)?_c('ErrorDisplay',{staticClass:"text-center text-danger",attrs:{"error":_vm.error}}):_vm._e(),(_vm.errorOccurred && !_vm.submitted)?_c('p',{staticClass:"my-3 text-center text-danger"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }