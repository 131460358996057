
import { Component, Vue } from 'vue-property-decorator';
import FormTextInput from '@/components/common/FormTextInput.vue';
import FormDropdownInput from '@/components/common/FormDropdownInput.vue';
import { Validation, validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import ApiError from '@/services/api/models/ApiError';
import { CreatePartnerUser, UpdatePartnerUsers } from '@/services/api/UsersApi';
import User from '@/store/modules/User';
import { trackEvent } from '@/services/Mixpanel';
import { mixins } from 'vue-class-component';
import AppName from '@/components/mixin/AppName.vue';
import PhoneNumberInput from '@/components/items/PhoneNumberInput.vue';

@Component({
  components: {
    FormTextInput,
    FormDropdownInput,
    PhoneNumberInput
  },
  validations: {
    form: {
      name: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required
      },
      role: {
        required
      }
    }
  },
  mixins: [validationMixin]
})
export default class AddUser extends mixins(AppName) {
  public error: ApiError | null = null;
  public form = { name: '', email: '', phone: '', role: '' };
  public submitted = false;
  public unresolvableError = false;
  public errorMessage = 'Missing fields';
  public errorOccurred = false;
  public app = 'milk';
  public colour = '#F6861F';
  public bootstrapColour = 'secondary';
  public width = 1000;

  mounted() {
    trackEvent('User viewing add user');

    this.app = this.getAppName(false);
    this.colour = this.getAppColour(false);
    this.bootstrapColour = this.getAppColour(true);
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
  }

  destroyed() {
    window.removeEventListener('resize', this.updateWidth);
  }

  public updateWidth() {
    this.width = window.innerWidth;
  }

  public async onSubmit(event: any) {
    event.preventDefault();
    this.$v.form.$touch();
    if (this.$v.form.$anyError && !this.validateForm()) {
      this.errorOccurred = true;
      return;
    }
    this.errorOccurred = false;

    await CreatePartnerUser({
      partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
      params: {
        ...this.form,
        role: this.form.role == 'Admin' ? 'partner.admin' : 'partner.user',
        partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0
      }
    }).then(response => {
      if (response == -1) {
        this.unresolvableError = true;
      }
      this.submitted = true;
      this.$root.$emit('usersUpdated');
      this.$bvToast.toast(
        this.unresolvableError
          ? `${this.form.name} has not been added`
          : `${this.form.name} has been added, password set to 'password'`,
        {
          title: this.unresolvableError ? 'User not added' : 'User added',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          append: false
        }
      );
    });
    trackEvent('User added user');

    this.$root.$emit('usersUpdated');
    this.$router.push(
      `/${this.getAppName(false)}-settings/tab/user-management`
    );
  }

  public validateForm(): boolean | null {
    if (this.form.name.length == 0 && this.form.name != ' ') {
      this.errorMessage = "Please enter the user's name";
      return false;
    }
    if (this.form.email.length == 0) {
      this.errorMessage = "Please enter the user's email";
      return false;
    }
    if (this.form.phone == null) {
      this.errorMessage = 'Phone number invalid';
      return false;
    }
    if (this.form.role.length == 0) {
      this.errorMessage = "Please enter the user's role";
      return false;
    }

    return true;
  }
}
